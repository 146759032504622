import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import React, { useState, useEffect } from "react";
import { roundOffToTwoDecimalPlaces } from "../ChatHelper";
import { textMsg } from "../constants";
import "../index.css";

const useStyles = makeStyles((theme) => ({
	root: {
		color: 'black',
		padding: '0 10px',
		fontSize: 'large',
		fontWeight: 'bold',
		boxShadow: "none !important",
		textAlign: "center",
		wordBreak: 'break-all'
	},
	textBox: {
		padding: '1rem'
	},
	heading: {
		paddingBottom: '1rem !Important'
	}
}));

const TextWithNewlines = ({ text }) => {
	const textWithLineBreaks = text.split('\n').map((line, index) => (
	  <React.Fragment key={index}>
		{line}
		<br />
	  </React.Fragment>
	));
  
	return (
		<React.Fragment>
			{textWithLineBreaks}
		</React.Fragment>
	);
}

const TextComponent = (props) => {
	const [displayText, setDisplayText] = useState("");
	const { aiType, applyTextStyles, heading, responseData, resultType, textStyle } = props;
	const classes = useStyles();

	useEffect(() => {
		console.log('aiType TextComp ::: *** ', aiType);
		let value = responseData
		if ((resultType && resultType === 'number') || (value && typeof value === 'number')) {
			value = roundOffToTwoDecimalPlaces(responseData);
		} else if (typeof value === 'string' && value === '') {
			value = textMsg
		}
		setDisplayText(value)
	}, [responseData, resultType]);

	return (
		<div className={classes.textBox}>
			{heading && <Typography variant="h5" component="p" className={classes.heading}>{heading}</Typography>}
			<div
				className={`typing-effect ${
					responseData !== '' && applyTextStyles ? `${classes.root} ${textStyle || ''}` : ''
				}`}
			>
				{aiType && aiType === "pgpt-md-qna" ? (<TextWithNewlines text={displayText} />) : (displayText)}
			</div>
		</div>
		
  	);
};

export default TextComponent;
